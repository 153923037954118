//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            form: this.$form.createForm(this),
            loading: false,
        }
    },
    methods: {
        handleSubmit() {
            if (this.loading) return
            this.loading = true
            this.form.validateFields((err, values) => {
                console.log('Received values of form: ', values)
                if (!err) {
                    this.$emit('submit', values)
                }
            })
        },
        handleRadioChage({ target: { value } }) {
            this.form.setFieldsValue({
                nbyj: value === '2' ? '駁回' : '已閱',
            })
        },
    },
}
