//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { statisticsNumber } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import { mapGetters } from 'vuex'
import { initWx } from '../components/minxin'
import category from './components/category.vue'
export default {
  components: {
    category,
    OpenData,
  },
  mixins: [initWx],
  data() {
    return {
      userId,
      corpId,
      account: {
        auditedNum: {
          route: {
            name: 'mobileDocumentApproval',
            params: {
              index: 3,
            },
          },
          auths: [],
          text: '已審公文數',
          num: 0,
        },
        unReceivedNum: {
          route: {
            name: 'mobileHome',
            query: {
              name: 'home',
            },
          },
          auths: [],
          text: '待收公文數',
          num: 0,
        },
        receivedNum: {
          route: {
            name: 'mobileHome',
            params: {
              index: 1,
            },
            query: {
              name: 'home',
            },
          },
          auths: [],
          text: '已收文數',
          num: 0,
        },
        acceptSendNum: {
          route: {
            name: 'mobileHome',
            query: {
              name: 'home',
            },
          },
          auths: [],
          text: '總收發數',
          num: 0,
        },
      },
    }
  },
  computed: {
    ...mapGetters(['supers', 'commons', 'commissioners', 'auth_code']),
  },
  created() {
    // this.configAuthList()
  },
  mounted() {
    this.getStatisticsNumber()
  },
  methods: {
    getStatisticsNumber() {
      statisticsNumber()
        .then((res) => {
          if (res.code === 200) {
            const keys = Object.keys(res.data)
            keys.forEach((element) => {
              this.account[element].num = res.data[element]
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toTranslate(i) {
      if (i.auths.includes(this.auth_code)) {
        this.$router.push(i.route)
      }
    },
    getNumZero(num) {
      let n = ''
      let i = 0
      while (i < num) {
        n += 0
        i++
      }
      return n
    },
    formatNum(num) {
      if (num === 0) return '0000'
      if (!num) return ''
      const str = num.toString()
      if (str.length > 3) return num
      return this.getNumZero(4 - str.length) + num
    },
    // 配置權限列表
    configAuthList() {
      const { auditedNum, unReceivedNum, receivedNum, acceptSendNum } = this.account
      auditedNum.auths = [...this.supers, ...this.commons]
      unReceivedNum.auths = [...this.supers, ...this.commons, ...this.commissioners]
      receivedNum.auths = [...this.supers, ...this.commons, ...this.commissioners]
      acceptSendNum.auths = [...this.supers, ...this.commons, ...this.commissioners]
    },
  },
}
